import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { PaymentsData, FundPhasesParams, PaymentIntentData } from "../Payment";
import { PaymentModel } from "../PaymentModel";

import { RestClient } from "./RestClient";


export class RestPaymentModel extends PaymentModel {
    static inject: Inject<RestPaymentModel> = singletonInject((injector) => {
        return () => new RestPaymentModel({restClient: injector.get(RestClient)()});
      });

  private readonly rest: RestClient;

  constructor(private readonly props: {restClient: RestClient}) {
    super();
    this.rest = props.restClient;
  }

  public getPaymentByProjectId(
    id: string
  ): Promise<PaymentsData> {
    return this.rest.get(`/payment/${id}`); 
  }

  public fundPhasesInProject(params: FundPhasesParams): Promise<PaymentIntentData> {
    return this.rest.put(`/payment/fund-phases`, params);
  }
}
