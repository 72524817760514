import { Settlement } from "Payment";
import { FirebaseFile } from "./File";

export const milestoneCompletionType = [
  "not-started",
  "in-progress",
  "needs-review",
  "pending",
  "completed",
] as const;
export type MilestoneCompletionType = (typeof milestoneCompletionType)[number];

export const changeRequestStatus = [
  "approved",
  "cancelled",
  "rejected",
  "submitted",
] as const;
export type ChangeRequestStatus = (typeof changeRequestStatus)[number];

export interface BudgetPhase {
  id?: string;
  name: string;
  budgetId: string;
  numDays: number;
  cost: number;
  orderIndex: number;
  milestoneIndices: number[];
}

export interface BudgetPhaseWithMilestones extends BudgetPhase {
  milestones: BudgetMilestone[];
}

export interface BudgetPhaseWithPayment extends BudgetPhaseWithMilestones {
  remainingBalance: number;
  balanceWithFees: number;
  changeRequests: ChangeRequest[];
  startDate: Date;
  lastPayment: Settlement;
}

export interface BudgetMilestoneTask {
  description: string;
  budget: number;
  completed: boolean;
  id?: string;
  orderIndex?: number;
}

export interface EditTask extends Omit<BudgetMilestoneTask, "budget"> {
  budget: string;
  orderIndex: number;
}

export interface BudgetMilestone {
  id?: string;
  name: string;
  budgetId: string;
  scopeOfWork: string;
  orderIndex: number;
  csiCode: string;
  completion: MilestoneCompletionType;
  tasks: BudgetMilestoneTask[];
  punchListItems?: PunchListItem[];
  files: FirebaseFile[];
}

export interface Budget {
  id: string;
  projectId: string;
  contractorId: string;
  completed: boolean;
  submitted: boolean;
  milestones: BudgetMilestone[];
  phases: BudgetPhase[];
  files: FirebaseFile[];
  disclosure: string;
  contract: Contract | undefined;
  startDate: string;
}

export type BudgetMilestoneTaskInput = Omit<BudgetMilestoneTask, "budget"> & {
  budget: string;
};

export type BudgetPhaseInput = BudgetPhase & {
  type: "phase";
  phaseIndex: number;
  renderIndex: number;
};

export type BudgetMilestoneInput = Omit<
  BudgetMilestone,
  "tasks" | "completion"
> & {
  type: "milestone";
  milestoneIndex: number;
  renderIndex: number;
  tasks: BudgetMilestoneTaskInput[];
  workAreas?: string[];
  completion?: MilestoneCompletionType;
};

export type BudgetInput = BudgetPhaseInput | BudgetMilestoneInput;

export interface Contract {
  completed: boolean;
  contractorSigned: boolean;
  ownerSigned: boolean;
  documentUrl: string;
}

export type ChangeRequestType = "change" | "complete" | "final";

export type ChangeRequestMilestoneType = "new" | "original";

export type ChangeRequestTaskType = "addition" | "change" | "deletion";

export interface ChangeRequestTask {
  taskId?: string;
  orderIndex: number;
  description: string;
  budget: number;
  completed: boolean;
  changeRequestTaskType: ChangeRequestTaskType;
}

export interface ChangeRequestMilestone {
  id?: string;
  orderIndex: number;
  csiCode: string;
  name: string;
  scope: string;
  tasks: ChangeRequestTask[];
  changeRequestMilestoneType: ChangeRequestMilestoneType;
}

export interface ChangeRequest {
  id: string;
  budgetId: string;
  applicantId: string;
  milestoneId?: string;
  createdAt: Date;
  milestones: ChangeRequestMilestone[];
  currentStatus: ChangeRequestStatus;
  changeRequestType: ChangeRequestType;
}

export interface PunchListItem {
  id?: string;
  description: string;
  completed: boolean;
}

export interface UpdateBudgetSignatureParams {
  budgetId: string;
  projectId: string;
  completed?: boolean;
  contractorSigned?: boolean;
  ownerSigned?: boolean;
  contractorSignatureUrl?: string;
  ownerSignatureUrl?: string;
  signatureUrlExpiresAt?: string;
  contractorSignatureId?: string;
  ownerSignatureId?: string;
  documentUrl?: string;
  signatureRequestId?: string;
}

export interface UpdateBudgetContractUrlParams {
  budgetId: string;
  url: string;
}

export interface UpdateDisclosureParams {
  budgetId: string;
  disclosure: string;
}

export interface UpdateBudgetParams {
  budgetId: string;
  deletedMilestones: any[];
  deletedPhases: any[];
  deletedTasks: BudgetMilestoneTask[];
  budgetItems: any[];
  startDate?: Date;
}

export interface CreateBudgetParams {
  readonly projectId: string;
  budgetItems?: any[];
  startDate: Date;
}

export interface UpdateBudgetMilestoneParams extends Partial<BudgetMilestone> {}

export interface UpdateBudgetPhasesParams {
  readonly budgetId: string;
  readonly phases: ReadonlyArray<BudgetPhase>;
}

export interface DeleteBudgetMilestoneParams {
  readonly budgetId: string;
  readonly orderIndex: number;
}

export interface DeleteBudgetPhaseParams {
  readonly budgetId: string;
  readonly orderIndex: number;
}

export interface CreateChangeRequestParams {
  budgetId: string;
  milestoneId?: string;
  milestones: ChangeRequestMilestone[];
  changeRequestType: ChangeRequestType;
}

export interface ApplyChangeRequestParams extends Partial<ChangeRequest> {}

export interface FileUploadParams {
  fileId: string;
  fileUrl: string;
  fileName: string;
  fileType: string;
}

export function isBudgetMilestoneInput(
  budgetInput: BudgetInput
): budgetInput is BudgetMilestoneInput {
  return budgetInput.type === "milestone";
}
