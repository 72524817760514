import { Authenticator } from "./exco-lib/exco-auth";
import {
  FirebaseWebAuthenticator,
  FirebaseWebThirdPartyAuthenticationProvidersInjectable,
} from "./exco-lib/exco-auth-firebase-web";
import { Injector, singletonInject } from "@not-the-droids/exco-ts-inject";
import firebase from "firebase/app";
import "firebase/auth";

import { createBrowserHistory } from "history";
import {
  BidModel,
  BudgetModel,
  CommentModel,
  ContractModel,
  ContractorModel,
  OwnerModel,
  ProjectModel,
  UserModel,
  VerificationModel,
} from "../../data-model";
import {
  RestBidModel,
  RestBudgetModel,
  RestClientConfigurationInjectable,
  RestCommentModel,
  RestContractModel,
  RestContractorModel,
  RestOwnerModel,
  RestProjectModel,
  RestUserModel,
  RestVerificationModel,
} from "../../data-model/rest";
import { HistoryInjectable } from "./HistoryInjectable";
import { RestPaymentModel } from "../../data-model/rest/RestPaymentModel";
import { PaymentModel } from "../../data-model/PaymentModel";
import { AuthPasscodeModel } from "../../data-model/AuthPasscodeModel";
import { RestAuthPasscodeModel } from "../../data-model/rest/RestAuthPasscodeModel";

export function createAppInjector(): Injector {
  const injector = new Injector();

  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    });
  }

  injector.bind(Authenticator, FirebaseWebAuthenticator);
  injector.bind(FirebaseWebThirdPartyAuthenticationProvidersInjectable, {
    inject: singletonInject(() => () => ({})),
  });

  injector.bind(HistoryInjectable, {
    inject: singletonInject(() => () => createBrowserHistory()),
  });

  injector.bind(RestClientConfigurationInjectable, {
    inject: singletonInject(() => () => ({
      baseUrl: process.env.REACT_APP_API_BASE_URL || "/api",
      getAuthToken: async (forceRefresh) =>
        (await firebase.auth().currentUser?.getIdToken(forceRefresh)) ??
        undefined,
    })),
  });
  injector.bind(PaymentModel, RestPaymentModel);

  injector.bind(ProjectModel, RestProjectModel);
  injector.bind(BidModel, RestBidModel);
  injector.bind(BudgetModel, RestBudgetModel);
  injector.bind(CommentModel, RestCommentModel);
  injector.bind(UserModel, RestUserModel);
  injector.bind(ContractModel, RestContractModel);
  injector.bind(ContractorModel, RestContractorModel);
  injector.bind(OwnerModel, RestOwnerModel);
  injector.bind(VerificationModel, RestVerificationModel);
  injector.bind(AuthPasscodeModel, RestAuthPasscodeModel);

  return injector;
}
