import { Inject } from "@not-the-droids/exco-ts-inject";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet } from "react-native";
import { Redirect } from "react-router";
import { AuthenticationViewFactory } from "../components/AuthenticationView";
import {
  InjectedSideBar,
  Page,
  Screen,
  StyledText,
} from "../components/controls";
import { InjectedFactoryComponent } from "../InjectorContext";
import { getRoute } from "../routes";
import { UserViewModel } from "../viewModels/UserViewModel";

interface Props {
  userViewModel: UserViewModel;
}

@observer
export class LandingScreen extends React.Component<Props> {
  static inject: Inject<React.ReactElement> = (injector) => {
    return () => (
      <LandingScreen userViewModel={injector.get(UserViewModel)()} />
    );
  };

  get currentUser() {
    return this.props.userViewModel.currentUser;
  }

  render() {
    if (this.currentUser) {
      return <Redirect to={getRoute("dashboard").path} />;
    }

    return (
      <Screen>
        <InjectedSideBar size="large" footer="copyright">
          <InjectedFactoryComponent
            factory={AuthenticationViewFactory}
            props={{}}
          />
        </InjectedSideBar>
        <Page variant="splash" withBorder={true}>
          <StyledText variant="heading" style={styles.heading}>
            Financial, Transactional, Accountability. For All.
          </StyledText>
        </Page>
      </Screen>
    );
  }
}

const styles = StyleSheet.create({
  heading: {
    // Placeholder: remove when done with placeholder text - EY
    marginBottom: 16,
  },
});
