import React from "react";
import { StyleSheet, View } from "react-native";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { BudgetModel, Project, ProjectModel } from "../../../data-model";
import { withInjectedFactory } from "../InjectorContext";
import { ProjectDetailsFlow } from "../flows/ProjectDetailsFlow";
import { InjectedProjectMilestonesView } from "./ProjectMilestonesManagementView";
import { InjectedProjectChangeRequestsView } from "./ProjectChangeRequestsView";
import { LoadingIndicator } from "./LoadingIndicator";
import { ProjectWidgetManager } from "../managers/ProjectWidgetManager";
import { PaymentModel } from "../../../data-model/PaymentModel";
import { mapProjectPayments } from "../utils/Budget";

interface CreateProps {
  project: Project;
}

interface Props {
  budgetModel: BudgetModel;
  paymentModel: PaymentModel;
  projectDetailsFlow: ProjectDetailsFlow;
  projectModel: ProjectModel;
  projectWidgetManager: ProjectWidgetManager;
}

export interface MilestoneManagementProps {
  changeView: () => void;
  contractorName: string;
  isManagementFocus: boolean;
  ownerName: string;
}

export const MyContext = React.createContext({
  changeView: () => {},
  contractorName: " ",
  isManagementFocus: true,
  ownerName: " "
});

class ProjectMilestonesControlViewFactory {
  static inject: Inject<ProjectMilestonesControlViewFactory> = (injector) => {
    return () =>
      new ProjectMilestonesControlViewFactory({
        budgetModel: injector.get(BudgetModel)(),
        paymentModel: injector.get(PaymentModel)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectModel: injector.get(ProjectModel)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectMilestonesControlView {...this.props} {...props} />;
  }
}

@observer
class ProjectMilestonesControlView extends React.Component<Props & CreateProps> {
  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  @observable private contractorName: string = '';
  @observable private initialized: boolean = false;
  @observable private isManagementFocus: boolean = true;
  @observable private ownerName: string = '';

  readonly componentDidMount = async () => {
    const { budgetModel, paymentModel, project, projectDetailsFlow, projectModel, projectWidgetManager } = this.props;
    try {
      const budget = await budgetModel.getBudgetByProjectId(project.id);
      const contractor = await projectModel.getContractorByProjectId(project.id);
      const owner = await projectModel.getOwnerByProjectId(project.id);
      this.contractorName = contractor.name;
      this.ownerName = owner.name;

      await projectWidgetManager.loadComments(project.id, "budget");
      await projectWidgetManager.loadChangeRequestComments(project.id);

      projectWidgetManager.selectedChangeRequestMenuItem = "Details";
      projectWidgetManager.activeCommentType = "budget";
      projectDetailsFlow.changeRequests = await budgetModel.getChangeRequestsByBudgetId(budget.id);
      projectDetailsFlow.budget = budget;
      projectDetailsFlow.selectedMilestone = undefined;
      projectDetailsFlow.selectedPhase = undefined;

      const payment = await paymentModel.getPaymentByProjectId(project.id);
      projectDetailsFlow.paymentData = mapProjectPayments(payment);
      
      this.initialized = true;
    } catch (error) {
      console.log(error);
    }
  };

  readonly handleViewChange = () => {
    this.isManagementFocus = !this.isManagementFocus;
  }

  render() {
    if (!this.initialized) return <LoadingIndicator />;
    return (
      <MyContext.Provider 
        value={{
          changeView: this.handleViewChange,
          contractorName: this.contractorName,
          isManagementFocus: this.isManagementFocus,
          ownerName: this.ownerName,
        }}
      >
        <View style={styles.container}>
          {
            this.isManagementFocus ? (
              <InjectedProjectMilestonesView project={this.props.project}/>
            ) : (
              <InjectedProjectChangeRequestsView project={this.props.project}/>
            )
          }
        </View>
      </MyContext.Provider>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    marginTop: -65,
    marginRight: -84,
    marginBottom: -98,
  },
});

export const InjectedProjectMilestonesViewControl = withInjectedFactory(
  ProjectMilestonesControlViewFactory
);
