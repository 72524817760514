import { FunctionComponent, useState } from "react";
import {
  Modal,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { RoutingData } from "../../../data-model/Payment";
import { formatCurrencyToString } from "../utils/Numbers";
import { StyledButton, StyledText } from "./controls";
import { Palette } from "./styles";

interface PaymentModalProps {
  total: number;
  routingData: RoutingData;
  onHideModal: () => void;
  onConfirmPayment?: () => void | Promise<void>;
}

export const PaymentModal: FunctionComponent<PaymentModalProps> = ({
  total,
  routingData,
  onHideModal,
  onConfirmPayment,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const accountName = routingData?.payeeName;
  const accountNumber = routingData?.accountNumber;
  const bankAddress = routingData?.bankAddress;
  const bankName = routingData?.bankName;
  const wireRoutingNumber = routingData?.routingNumber;

  const handleConfirmPayment = async () => {
    setSubmitting(true);
    await onConfirmPayment!();
    setSubmitting(false);
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={true}
      onRequestClose={onHideModal}
    >
      <View style={styles.centeredView}>
        {/* Background */}
        <TouchableWithoutFeedback onPress={onHideModal}>
          <View style={styles.background} />
        </TouchableWithoutFeedback>

        {/* Modal */}
        <View style={styles.modalView}>
          <View style={styles.section}>
            <StyledText style={styles.title} variant="heading3">
              Wire Transfer Instructions
            </StyledText>
            <StyledText style={styles.subtitle}>
              In order to fund this phase, please initiate a Wire 
              transfer from your bank to the account below.
            </StyledText>
          </View>
          <View style={styles.section}>
            <StyledText style={styles.sectionTitle}>Amount</StyledText>
            <StyledText style={styles.sectionText}>
              {formatCurrencyToString(total)}
            </StyledText>

            <StyledText style={styles.sectionTitle}>Bank name</StyledText>
            <StyledText style={styles.sectionText}>{bankName}</StyledText>
            
            <StyledText style={styles.sectionTitle}>Wire Routing Number</StyledText>
            <StyledText style={styles.sectionText}>{wireRoutingNumber}</StyledText>

            <StyledText style={styles.sectionTitle}>Account Number</StyledText>
            <StyledText style={styles.sectionText}>{accountNumber}</StyledText>

            <StyledText style={styles.sectionTitle}>Account Name</StyledText>
            <StyledText style={styles.sectionText}>{accountName}</StyledText>

            <StyledText style={styles.sectionTitle}>Bank Address</StyledText>
            <StyledText style={styles.sectionText}>{bankAddress}</StyledText>

            {/* <StyledText style={styles.disclaimer}>
              Lorem ipsum disclaimer text dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua. Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur.
            </StyledText> */}
          </View>

          {onConfirmPayment && (
            <StyledButton
              onPress={handleConfirmPayment}
              text="Confirm Payment"
              loading={submitting}
              disabled={submitting}
            />
          )}

          <StyledButton
            variant={onConfirmPayment ? "link" : "primary"}
            onPress={onHideModal}
            text={
              onConfirmPayment
                ? "No, I don't want to make this payment"
                : "Close"
            }
            style={onConfirmPayment && styles.cancelButton}
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 24,
    borderBottomColor: Palette.Primary10Pct,
    borderBottomWidth: 1,
    width: "100%",
    paddingHorizontal: 35,
  },
  sectionText: {
    fontSize: 20,
    fontWeight: "600",
    lineHeight: 28,
    marginBottom: 16,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  background: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    width: "35vh",
    maxWidth: 600,
    borderRadius: 8,
    paddingVertical: 35,
    alignItems: "center",
    elevation: 5,
    minWidth: 500,
  },
  title: {
    fontSize: 20,
    fontWeight: "600",
    lineHeight: 28,
  },
  subtitle: {
    fontSize: 13,
    fontWeight: "400",
    lineHeight: 20,
    marginBottom: 16,
  },
  sectionTitle: {
    fontSize: 13,
    fontWeight: "400",
    lineHeight: 20,
  },
  disclaimer: {
    fontSize: 10,
    fontWeight: "400",
    lineHeight: 16,
    color: Palette.Primary50Pct,
    marginTop: 16,
    marginBottom: 8,
  },
  cancelButton: {
    marginTop: 24,
  },
});

// interface PaymentToolTipProps {
//   text: string;
// }

// const PaymentModalToolTip: FunctionComponent<PaymentToolTipProps> = (
//   props
// ) => {
//   const [height, setHeight] = useState<number | undefined>();
//   const renderRef = useRef(false);
//   const statusRef = useRef(null);
//   const isStatusHover = useHover(statusRef);
//   const visibleStyle = {
//     opacity: height ? 1 : 0,
//     top: 7 - ((height ?? 0) / 2),
//   };

//   return (
//     <View style={toolTipStyle.container}>
//       {props.children}
//       <View style={{width: "auto"}} ref={statusRef}>
//         <Icon name={"info"} type={"primary"} size={14}/>
//         {isStatusHover ? (
//           <View 
//             style={[toolTipStyle.tooltip, visibleStyle]} 
//             onLayout={({ nativeEvent: { layout } }) => {
//               if (!renderRef.current) {
//                 renderRef.current = false;
//                 setHeight(layout.height);
//               }
//             }}
//           >
//             <View style={toolTipStyle.arrow} />
//             <StyledText variant="body2" colorMode="light">
//               {props.text}
//             </StyledText>
//           </View>
//         ) : null}
//       </View>
//     </View>
//   );
// };

// const toolTipStyle = StyleSheet.create({
//   container: {
//     flexDirection: "row",
//     alignItems: "center",
//     gap: 8,
//     maxWidth: "100%",
//   },
//   tooltip: {
//     flex: 1,
//     position: "absolute",
//     left: 25,
//     alignItems: "center",
//     justifyContent: "center",
//     minWidth: 100,
//     minHeight: 24,
//     borderRadius: 4,
//     backgroundColor: Palette.Primary100Pct,
//     padding: 12,
//     width: 300,
//   },
//   arrow: {
//     width: 0,
//     height: 0,
//     borderWidth: 5,
//     borderStyle: "solid",
//     borderColor: "transparent",
//     position: "absolute",
//     left: -10,
//     borderRightColor: Palette.Primary100Pct,
//   },
// });
