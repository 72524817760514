import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { View, StyleSheet } from "react-native";
import { BudgetMilestone, ChangeRequest, Project } from "../../../data-model";

import { StyledButton, StyledText, StyledTouchableOpacity } from "./controls";
import { ProjectDetailsFlow } from "../flows/ProjectDetailsFlow";

import { withInjectedFactory } from "../InjectorContext";
import { PaymentModel } from "../../../data-model/PaymentModel";
import { Palette } from "./styles";

import { SummaryLayout } from "./SummaryLayout";
import CheckBox from "./CheckBox";
import { formatCurrencyToString } from "../utils/Numbers";
import { Tag } from "./controls/Tag";
import { formatPaymentPhaseDateTextAndTag } from "../utils/Budget";
import { PaymentIntentData } from "../../../data-model/Payment";
import { PaymentModal } from "./PaymentModal";

interface Props {
  projectDetailsFlow: ProjectDetailsFlow;
  paymentModel: PaymentModel;
}

interface CreateProps {
  project: Project;
}

export class ProjectPaymentsPaymentViewFactory {
  static inject: Inject<ProjectPaymentsPaymentViewFactory> = (injector) => {
    return () =>
      new ProjectPaymentsPaymentViewFactory({
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        paymentModel: injector.get(PaymentModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectPaymentsPaymentView {...this.props} {...props} />;
  }
}

@observer
class ProjectPaymentsPaymentView extends React.Component<CreateProps & Props> {
  @observable private _paymentLoading = false;
  @observable public paymentIntentModal: PaymentIntentData | undefined 

  @computed get selectedPhases() {
    return this.props.projectDetailsFlow.selectedPaymentPhases;
  }

  @computed get openedPhases() {
    return this.props.projectDetailsFlow.openedPaymentPhases;
  }

  @computed get paymentTotal() {
    const { paymentData } = this.props.projectDetailsFlow;
    if (!paymentData) return 0;
    const filtered = paymentData.phases.filter((phase) =>
      this.selectedPhases.includes(phase.id!)
    );
    return filtered.reduce(
      (acc: number, phase) => acc + Number(phase.balanceWithFees),
      0
    );
  }

  @computed get allSelectablePhases() {
    return (
      this.props.projectDetailsFlow.paymentData?.phases.filter(
        (p) => p.balanceWithFees > 0
      ) ?? []
    );
  }

  @computed get allPhasesChecked() {
    return this.selectedPhases.length === this.allSelectablePhases.length;
  }

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  private handleBackClick = () => {
    const { projectDetailsFlow } = this.props;
    projectDetailsFlow.selectedPaymentPhases = [];
    projectDetailsFlow.openedPaymentPhases = [];
    projectDetailsFlow.paymentView = "dashboard";
  };

  private handleCheckBoxClick = (id: string, newValue: boolean) => {
    const { projectDetailsFlow } = this.props;
    if (newValue) projectDetailsFlow.selectedPaymentPhases.push(id);
    else
      projectDetailsFlow.selectedPaymentPhases =
        projectDetailsFlow.selectedPaymentPhases.filter((x) => x !== id);
  };

  private handleCheckAllClick = () => {
    const { projectDetailsFlow } = this.props;
    if (projectDetailsFlow.selectedPaymentPhases.length > 0) {
      projectDetailsFlow.selectedPaymentPhases = [];
    } else {
      projectDetailsFlow.selectedPaymentPhases = this.allSelectablePhases.map(
        (p) => p.id!
      );
    }
  };

  private handleOpenClick = (id: string, newValue: boolean) => {
    const { projectDetailsFlow } = this.props;
    if (newValue) projectDetailsFlow.openedPaymentPhases.push(id);
    else
      projectDetailsFlow.openedPaymentPhases =
        projectDetailsFlow.openedPaymentPhases.filter((x) => x !== id);
  };

  private initiatePayment = async () => {
    const { project, paymentModel, projectDetailsFlow } = this.props;
    try {
      try {
        this._paymentLoading = true;
        this.paymentIntentModal = await paymentModel.fundPhasesInProject({
          projectId: project.id,
          phaseIds: projectDetailsFlow.selectedPaymentPhases,
        });
        projectDetailsFlow.selectedPaymentPhases = [];
        // TODO: Remove - Temporary additional call to retrieve data
        await projectDetailsFlow.getPaymentData(project.id);
      } finally {
        this._paymentLoading = false;
      }
      this._paymentLoading = false;
    } catch (e) {
      console.log(e);
    } finally {
      this._paymentLoading = false;
    }
  };

  private renderFooter = () => {
    return (
      <>
        <View style={styles.footerPriceContainer}>
          <StyledText isMedium style={styles.text}>
            Total paying today:
          </StyledText>
          <StyledText isBold={true} variant="heading2" style={styles.priceText}>
            {formatCurrencyToString(this.paymentTotal)}
          </StyledText>
        </View>
        <StyledButton
          disabled={
            this.props.projectDetailsFlow.selectedPaymentPhases.length === 0
          }
          loading={this._paymentLoading}
          style={styles.payButton}
          variant="primary"
          onPress={this.initiatePayment}
          text={"Continue"}
        />
      </>
    );
  };

  render() {
    const { projectDetailsFlow } = this.props;
    return (
      <>
        <SummaryLayout
          title="Select Phase"
          subtitle="Please select a part of the Kitchen Remodal project to apply your payment to. You may select the entire project or individual phases."
          onBackPress={this.handleBackClick}
          backButtonHidden={false}
          footer={this.renderFooter()}
          cardPadding={8}
        >
          <>
            <View style={styles.checkAllContainer}>
              <CheckBox
                value=""
                checked={this.allPhasesChecked}
                onChange={this.handleCheckAllClick}
              />
              <StyledText
                variant="body"
                colorMode={"dark"}
                isUpperCase
                isBold={true}
                style={{ marginLeft: "0.5vw" }}
              >
                Project Phases
              </StyledText>
            </View>

            {projectDetailsFlow.paymentData?.phases.map((phase) => {
              const disabled = phase.balanceWithFees <= 0;
              const selected = this.selectedPhases.includes(phase.id!);
              const opened = this.openedPhases.includes(phase.id!);
              const numberOfChanges = phase.changeRequests.length;
              const { startsText, startsTextColor } =
                formatPaymentPhaseDateTextAndTag(phase);
              return (
                <StyledTouchableOpacity
                  key={phase.id!}
                  style={[
                    styles.phase,
                    opened ? styles.phaseOpened : {},
                    disabled && { opacity: 0.5 },
                  ]}
                  onPress={
                    !disabled
                      ? (e) => {
                          if (
                            e.target.toString() === "[object HTMLInputElement]"
                          )
                            return;
                          this.handleOpenClick(phase.id!, !opened);
                        }
                      : undefined
                  }
                >
                  {/* Head */}
                  <View style={styles.phaseInfo}>
                    {/* Left Container */}
                    <View style={styles.itemContainer}>
                      <CheckBox
                        disabled={disabled}
                        value=""
                        checked={this.selectedPhases.includes(phase.id!)}
                        onChange={
                          !disabled
                            ? (e) =>
                                this.handleCheckBoxClick(phase.id!, !selected)
                            : undefined
                        }
                      />
                      <StyledText
                        variant="body"
                        colorMode={"dark"}
                        isBold={true}
                        style={{ marginLeft: "0.5vw" }}
                      >
                        {phase.name}
                      </StyledText>
                    </View>

                    {/* Right Container */}
                    <View style={styles.itemContainer}>
                      {/* Completed tag or start date */}
                      {phase.milestones.every(
                        (m: BudgetMilestone) => m.completion === "completed"
                      ) && phase.balanceWithFees <= 0 ? (
                        <Tag
                          text="Completed"
                          type="affirm"
                          style={{ marginRight: "2vw" }}
                        />
                      ) : (
                        // If phase changed add another tag
                        <>
                          {numberOfChanges > 0 && (
                            <Tag
                              text={
                                numberOfChanges > 1
                                  ? `${numberOfChanges} changes`
                                  : "1 change"
                              }
                              type="secondary"
                              style={{ marginRight: "0.5vw" }}
                            />
                          )}
                          <StyledText
                            variant="body"
                            style={{
                              marginRight: "2vw",
                              color: startsTextColor,
                            }}
                          >
                            {startsText}
                          </StyledText>
                        </>
                      )}

                      {/* Price */}
                      <StyledText
                        variant="body"
                        colorMode={"dark"}
                        isBold={true}
                      >
                        {formatCurrencyToString(phase.balanceWithFees, "$0.00")}
                      </StyledText>
                    </View>
                  </View>
                  {/* Body */}
                  {opened && (
                    <View style={styles.bodyContainer}>
                      <StyledText
                        variant="body"
                        colorMode={"dark"}
                        isBold
                        style={styles.milestoneTitle}
                      >
                        {numberOfChanges > 0
                          ? "Changed Milestones"
                          : "Milestones"}
                      </StyledText>

                      {/* Render milestones in change requests */}
                      {numberOfChanges > 0 &&
                        phase.changeRequests.map((changeRequest: ChangeRequest) =>
                          changeRequest.milestones
                            .filter(
                              (m) => m.changeRequestMilestoneType === "original"
                            )
                            .map((milestone) => (
                              <View
                                style={styles.milestone}
                                key={changeRequest.id!}
                              >
                                <StyledText
                                  variant="body"
                                  colorMode={"dark"}
                                  isBold
                                >
                                  {milestone.name}
                                </StyledText>
                                <StyledText
                                  variant="body"
                                  colorMode={"dark"}
                                  isMedium
                                >
                                  Change order: #
                                  {changeRequest.id.split("-")[0].toUpperCase()}
                                </StyledText>
                              </View>
                            ))
                        )}

                      {/* Render milestones in a phase if there is no change request */}
                      {numberOfChanges === 0 &&
                        phase.milestones.map((milestone: BudgetMilestone) => {
                          const milestoneCost = milestone.tasks.reduce(
                            (acc, task) => acc + Number(task.budget),
                            0
                          );

                          return (
                            <View style={styles.milestone} key={milestone.id!}>
                              <StyledText
                                variant="body"
                                colorMode={"dark"}
                                isBold
                              >
                                {milestone.name}
                              </StyledText>
                              <StyledText
                                variant="body"
                                colorMode={"dark"}
                                isMedium
                              >
                                {formatCurrencyToString(milestoneCost, "$0.00")}
                              </StyledText>
                            </View>
                          );
                        })}
                    </View>
                  )}
                </StyledTouchableOpacity>
              );
            })}
          </>
        </SummaryLayout>
        {this.paymentIntentModal && (
          <PaymentModal
            onHideModal={() => (this.paymentIntentModal = undefined)}
            total={this.paymentIntentModal.total}
            routingData={this.paymentIntentModal.routingData}
          />
        )}
        {/* {this.renderSubmitButton()} */}
      </>
    );
  }
}

const styles = StyleSheet.create({
  checkAllContainer: {
    marginVertical: 8,
    marginLeft: 18,
    flexDirection: "row",
    alignItems: "center",
  },
  phase: {
    backgroundColor: Palette.White,
    borderWidth: 2,
    borderColor: Palette.Primary10Pct,
    borderRadius: 6,
    zIndex: 1,
    padding: 16,
    marginVertical: 2,
  },
  phaseOpened: {
    borderWidth: 2,
    borderColor: Palette.Primary25Pct,
  },
  phaseInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  bodyContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
  footerPriceContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 40,
  },
  text: {
    fontSize: 16,
  },
  priceText: {
    position: "absolute",
    right: 30,
  },
  payButton: {
    width: 200,
  },
  milestoneTitle: {
    marginTop: 16,
    marginBottom: 6,
  },
  milestone: {
    padding: 8,
    backgroundColor: Palette.Primary5Pct,
    borderRadius: 6,
    marginVertical: 2,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export const InjectedProjectPaymentsPaymentView = withInjectedFactory(
  ProjectPaymentsPaymentViewFactory
);
