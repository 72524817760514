import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { computed, observable, makeObservable } from "mobx";
import {
  Budget,
  BudgetMilestone,
  BudgetPhase,
  ChangeRequest,
  ChangeRequestMilestone,
} from "../../../data-model/Budget";
import { Bid, BidLine } from "../../../data-model/Bid";
import { parsePriceInput, parseSavedPriceToInput } from "../utils/Numbers";
import { Contractor, InvitedUser, Owner } from "../../../data-model";

import { PaymentModel } from "../../../data-model/PaymentModel";
import { mapProjectPayments } from "../utils/Budget";
import { ProjectPayment } from "../../../data-model/Payment";

interface Props {
  paymentModel: PaymentModel;
}

// types to handle input validation regarding the decimal is inputs.

export type BudgetOptionsRequired = "Yes" | "No" | "Without";

export type ManagementMilestoneType = "milestone" | "changeRequest";

export type ManagementMilestone = (BudgetMilestone | ChangeRequestMilestone) & {
  type: ManagementMilestoneType;
};

export type BidLineInput = Omit<BidLine, "cost"> & {
  cost: string;
  workAreas?: string[];
};

export type BidInput = Omit<Bid, "lines"> & {
  lines: BidLineInput[];
};

export const mapBidToBidInput = (bid: Bid): BidInput => ({
  ...bid,
  lines: bid.lines.map((line) => ({
    ...line,
    cost: parseSavedPriceToInput(line.cost),
  })),
});

export const mapBidInputToBid = (bid: BidInput): Bid => ({
  ...bid,
  lines: bid.lines.map((line) => ({
    ...line,
    cost: parsePriceInput(line.cost),
  })),
});

export const mapMilestoneToManagementMilestone = (
  milestone: BudgetMilestone | ChangeRequestMilestone
): ManagementMilestone => ({
  ...milestone,
  //@ts-ignore
  type: milestone["budgetId"] ? "milestone" : "changeRequest",
});

export class ProjectDetailsFlow {
  static inject: Inject<ProjectDetailsFlow> = singletonInject((injector) => {
    return () =>
      new ProjectDetailsFlow({
        paymentModel: injector.get(PaymentModel)(),
      });
  });

  constructor(private readonly props: Props) {
    makeObservable(this);
  }

  // Section: project
  @observable public owner: Owner | undefined;
  @observable public contractor: Contractor | undefined;
  @observable public invites: InvitedUser[] = [];

  // Section: bid flow
  @observable public bid: Bid | undefined;
  @observable public isBidEditView: boolean = false;
  @observable public bidInput: BidInput | undefined;
  @observable public bidLinesAdded: BidLineInput[] = [];
  @observable public bidLinesDeleted: BidLineInput[] = [];

  @computed get projectedTotal(): number {
    let totalCost: number = 0;
    if (this.bidInput) {
      this.bidInput.lines?.forEach((bidLine) => {
        totalCost += parsePriceInput(bidLine.cost);
      });
      this.bidLinesAdded.forEach((bidLine) => {
        totalCost += parsePriceInput(bidLine.cost);
      });
    } else if (this.bid) {
      this.bid.lines?.forEach((bidLine) => {
        totalCost += parsePriceInput(bidLine.cost);
      });
    }

    return totalCost;
  }

  // Section: budget
  @observable public isBudgetEditView: boolean = true;
  @observable public budget: Budget | undefined;
  @observable public userSawLastBudgetMilestone: boolean = false;
  @observable public arePermitsRequired: BudgetOptionsRequired = "Yes";
  @observable public isInspectionRequired: BudgetOptionsRequired = "Yes";
  @observable public selectedMilestone?: ManagementMilestone;
  @observable public selectedPhase?: BudgetPhase;
  @observable public selectedChangeRequest?: ChangeRequest;
  @observable public changeRequests: ChangeRequest[] = [];

  @computed public get phasesTotalCost(): number {
    let cost: number = 0;
    if (this.budget?.phases) {
      cost = this.budget.phases.reduce(
        (previousValue, currentValue) =>
          previousValue + Number(currentValue.cost),
        0
      );
    }
    return Math.round((cost + Number.EPSILON) * 100) / 100;
  }

  @computed public get phasesTotalDays(): number {
    return (
      this.budget?.phases?.reduce(
        (previousValue, currentValue) => previousValue + currentValue.numDays,
        0
      ) || 0
    );
  }

  // Section: Payments
  @observable public paymentView:
    | "dashboard"
    | "make-payment"
    | "confirmation" = "dashboard";
  @observable public paymentData: ProjectPayment | undefined;
  @observable public paymentConfirmation: any = undefined;
  @observable public selectedPaymentPhases: string[] = [];
  @observable public openedPaymentPhases: string[] = [];

  readonly getPaymentData = async (projectId: string) => {
    const payment = await this.props.paymentModel.getPaymentByProjectId(
      projectId
    );
    this.paymentData = mapProjectPayments(payment);
  };

  readonly clearProject = () => {
    this.owner = undefined;
    this.contractor = undefined;
    this.bid = undefined;
    this.isBidEditView = false;
    this.bidInput = undefined;
    this.bidLinesAdded = [];
    this.bidLinesDeleted = [];
    this.isBudgetEditView = true;
    this.budget = undefined;
    this.userSawLastBudgetMilestone = false;
    this.arePermitsRequired = "Yes";
    this.isInspectionRequired = "Yes";
    this.selectedMilestone = undefined;
    this.selectedPhase = undefined;
    this.selectedChangeRequest = undefined;
    this.changeRequests = [];
    this.paymentView = "dashboard";
    this.paymentData = undefined;
    this.paymentConfirmation = undefined;
    this.selectedPaymentPhases = [];
    this.openedPaymentPhases = [];
  };
}
