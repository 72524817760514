import React from "react";
import { StyleSheet, View } from "react-native";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import {
  BudgetMilestone,
  BudgetModel,
  ChangeRequest,
  MilestoneCompletionType,
  Project,
  ProjectModel,
  Contract,
  ContractModel,
} from "../../../data-model/";
import { withInjectedFactory } from "../InjectorContext";
import {
  Icon,
  StyledButton,
  StyledText,
  StyledTouchableOpacity,
} from "./controls";
import { LoadingIndicator } from "./LoadingIndicator";
import { InjectedManagementSidebarView } from "./ManagementSidebarView";
import {
  mapMilestoneToManagementMilestone,
  ProjectDetailsFlow,
} from "../flows/ProjectDetailsFlow";
import { ProjectWidgetManager } from "../managers/ProjectWidgetManager";
import { Palette } from "./styles";
import { InjectedChangeRequestView } from "./ChangeRequestView";
import { UserViewModel } from "../viewModels/UserViewModel";
import {
  capitalizeFirstLetter,
  formatChangeRequestTag,
  structureAddress,
} from "../utils/Strings";
import { StyledModal } from "./Modal";
import { Notification } from "../NotificationInjectable";
import {
  MilestoneManagementProps,
  MyContext,
} from "./ProjectMilestonesViewControl";
import { ConfirmAgreementView } from "./Confirmation/ConfirmAgreementView";

interface Props {
  budgetModel: BudgetModel;
  contractModel: ContractModel;
  projectDetailsFlow: ProjectDetailsFlow;
  projectModel: ProjectModel;
  projectWidgetManager: ProjectWidgetManager;
  userViewModel: UserViewModel;
  notification: Notification;
}

interface CreateProps {
  project: Project;
}

class ProjectChangeRequestsViewFactory {
  static inject: Inject<ProjectChangeRequestsViewFactory> = (injector) => {
    return () =>
      new ProjectChangeRequestsViewFactory({
        budgetModel: injector.get(BudgetModel)(),
        contractModel: injector.get(ContractModel)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectModel: injector.get(ProjectModel)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        userViewModel: injector.get(UserViewModel)(),
        notification: injector.get(Notification)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return (
      <MyContext.Consumer>
        {(consumer) => (
          <ProjectChangeRequestsView {...this.props} {...props} {...consumer} />
        )}
      </MyContext.Consumer>
    );
  }
}

@observer
class ProjectChangeRequestsView extends React.Component<
  Props & CreateProps & MilestoneManagementProps
> {
  constructor(props: Props & CreateProps & MilestoneManagementProps) {
    super(props);
    makeObservable(this);
  }

  @observable private _submitting = false;
  @observable private _loadingDetail = false;
  @observable private _initialized = false;
  @observable private showConfirmApprovalModal = false;
  @observable private showConfirmCompletionModal = false;
  @observable private showApprovalErrorModal = false;
  @observable private showCompletionErrorModal = false;
  @observable private isConfirmationAddressChecked = false;
  @observable private isConfirmationTermsChecked = false;
  @observable private contractInfo: Contract | undefined;

  @computed get userType(): "contractor" | "owner" {
    return this.props.userViewModel.isContractor ? "contractor" : "owner";
  }

  @computed public get tabs() {
    const {
      project,
      projectDetailsFlow: { changeRequests },
    } = this.props;
    const orderdChangeRequests = [
      ...changeRequests.filter((cr) => cr.currentStatus === "submitted"),
      ...changeRequests.filter((cr) => cr.currentStatus !== "submitted"),
    ];

    return (
      <>
        <View style={[styles.tab, styles.topTab, { height: 104 }]}>
          <StyledTouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              right: 38,
            }}
            onPress={() => this.props.changeView()}
          >
            <Icon name={"chevron-left"} type={"accent"} size={16} />
            <StyledText
              variant="body2"
              style={{ marginLeft: 5 }}
              colorMode="accent"
              isBold
            >
              {"Go Back"}
            </StyledText>
          </StyledTouchableOpacity>
          <StyledText variant="body" isBold={true}>
            {"CHANGE ORDER REQUESTS"}
          </StyledText>
        </View>
        {orderdChangeRequests.map(
          (changeRequest: ChangeRequest, index: number) => {
            const isCompleted = changeRequest.currentStatus !== "submitted";
            const isSelected =
              this.currentChangeRequest?.id === changeRequest.id;
            const colorMode = isSelected
              ? "accent"
              : isCompleted
              ? "gray"
              : "dark";
            const whiteBorder = isSelected && styles.whiteRightBorder;
            const circleColor = isSelected && styles.accentCircle;
            const lineThrough = isCompleted && styles.lineThrough;

            return (
              <StyledTouchableOpacity
                key={`change-request-tab-${index}`}
                onPress={() => this.handleChangeRequestPress(changeRequest)}
                style={[styles.tab, whiteBorder]}
              >
                <View style={[styles.circle, circleColor]} />
                <View>
                  <StyledText
                    style={lineThrough}
                    variant="body"
                    colorMode={colorMode}
                    isBold={true}
                  >
                    {formatChangeRequestTag(changeRequest)}
                  </StyledText>
                  <StyledText
                    variant="body2"
                    colorMode="gray"
                    style={{ marginTop: 4 }}
                  >
                    {project.name}
                  </StyledText>
                </View>
              </StyledTouchableOpacity>
            );
          }
        )}
      </>
    );
  }

  @computed public get totalProjectPrice(): number {
    let totalCost: number = 0;
    this.props.projectDetailsFlow.budget?.phases.forEach((phase: any) => {
      totalCost += Number(phase.cost);
    });
    return Math.round((totalCost + Number.EPSILON) * 100) / 100;
  }

  @computed public get currentChangeRequest() {
    return this.props.projectDetailsFlow.selectedChangeRequest;
  }

  @computed public get newChangeRequestName() {
    const newChangeRequest = this.currentChangeRequest?.milestones.find(
      (milestone) => milestone.changeRequestMilestoneType === "new"
    );

    if (newChangeRequest) {
      const isNewMilestone =
        this.props.projectDetailsFlow.selectedMilestone?.type ===
        "changeRequest";
      return `${isNewMilestone ? "New Milestone: " : ""}${
        newChangeRequest.name
      }`;
    } else {
      return undefined;
    }
  }

  @computed public get originalChangeRequestName() {
    const origChangeRequest = this.currentChangeRequest?.milestones.find(
      (milestone) => milestone.changeRequestMilestoneType === "original"
    );
    return origChangeRequest?.name;
  }

  @computed public get milestoneCompletion() {
    const {
      projectDetailsFlow: { selectedMilestone },
    } = this.props;
    let completionStatus: MilestoneCompletionType = "needs-review";
    const someTasksCompleted = selectedMilestone?.tasks.some(
      (task) => task.completed
    );
    if (someTasksCompleted) {
      completionStatus = "in-progress";
    } else {
      completionStatus = "not-started";
    }
    return completionStatus;
  }

  @computed public get showAcceptClickWrap() {
    return (
      this.currentChangeRequest?.currentStatus === "submitted" &&
      ((this.userType === "owner" && !this.contractInfo?.ownerSigned) ||
        (this.userType === "contractor" && this.contractInfo?.ownerSigned))
    );
  }

  readonly componentDidMount = async () => {
    const { contractModel } = this.props;
    if (this.currentChangeRequest?.changeRequestType === "change") {
      this.contractInfo = await contractModel.getChangeRequestContract(
        this.currentChangeRequest.id,
        false
      );
    }
    this._initialized = true;
  };

  readonly componentWillUnmount = () => {
    this.props.projectWidgetManager.setActiveCommentInfo();
    this.props.projectWidgetManager.activeChangeRequestCommentIndex = -2;
    this.props.projectWidgetManager.activeChangeRequestId = undefined;
    this.props.projectWidgetManager.activeCommentType = "budget";
  };

  readonly handleChangeRequestPress = async (changeRequest: ChangeRequest) => {
    const { contractModel, projectDetailsFlow, projectWidgetManager } =
      this.props;
    if (changeRequest.milestones.length === 0) {
      projectDetailsFlow.selectedMilestone = undefined;
      projectDetailsFlow.selectedPhase = undefined;
      projectWidgetManager.setActiveCommentInfo(
        projectDetailsFlow.budget?.id,
        "Project Completion"
      );
    } else {
      const milestone = !!changeRequest.milestoneId
        ? projectDetailsFlow.budget?.milestones.find(
            (milestone) => milestone.id === changeRequest.milestoneId
          )
        : changeRequest.milestones[0];
      const phase = projectDetailsFlow.budget?.phases.find(
        (phase) =>
          phase.milestoneIndices.some(
            (index) => index === milestone?.orderIndex
          ) ||
          phase.milestoneIndices[phase.milestoneIndices.length - 1] + 1 ===
            milestone?.orderIndex
      );

      if (milestone && phase) {
        projectDetailsFlow.selectedMilestone =
          mapMilestoneToManagementMilestone(milestone);
        projectDetailsFlow.selectedPhase = phase;
        projectWidgetManager.setActiveCommentInfo(
          projectDetailsFlow.budget?.id,
          milestone.name
        );
      }
    }
    projectDetailsFlow.selectedChangeRequest = changeRequest;
    projectWidgetManager.activeChangeRequestCommentIndex = -2;
    projectWidgetManager.activeChangeRequestId = undefined;

    if (this.currentChangeRequest?.changeRequestType === "change") {
      this._loadingDetail = true;
      await contractModel
        .getChangeRequestContract(this.currentChangeRequest?.id, false)
        .then((res) => (this.contractInfo = res))
        .catch((err) => {
          this.contractInfo = undefined;
          console.error(err);
        });
      this._loadingDetail = false;
    }
  };

  readonly handleChangeRequestApprove = async () => {
    if (this.currentChangeRequest?.changeRequestType === "complete") {
      this.showConfirmApprovalModal = true;
    } else if (this.currentChangeRequest?.changeRequestType === "final") {
      this.showConfirmCompletionModal = true;
    } else {
      this.handleChangeRequestConfirmationApproval();
    }
  };

  readonly handleChangeRequestConfirmationApproval = async () => {
    const { budgetModel, contractModel, projectDetailsFlow, notification } =
      this.props;
    this._submitting = true;

    const changeRequestId = this.currentChangeRequest!.id;

    this.contractInfo = await contractModel.getChangeRequestContract(
      changeRequestId
    );

    if (this.contractInfo?.[`${this.userType}Signed`]) {
      notification.setNotification(
        "success",
        "The change order has already been confirmed."
      );
      return;
    }

    try {
      this._submitting = true;
      await contractModel.confirmChangeRequestContract(changeRequestId);

      if (this.userType === "contractor") {
        projectDetailsFlow.selectedChangeRequest!.currentStatus = "approved";
        projectDetailsFlow.budget = await budgetModel.getBudgetById(
          projectDetailsFlow.budget!.id
        );
        const selectedId = projectDetailsFlow.selectedMilestone?.id;
        if (selectedId) {
          const milestone = projectDetailsFlow.budget.milestones.find(
            (m) => m.id === selectedId
          );
          projectDetailsFlow.selectedMilestone = {
            ...milestone!,
            type: "milestone",
          };
        }
      } else {
        this.contractInfo!.ownerSigned = true;
      }
      notification.setNotification(
        "success",
        "The change order has been confirmed!"
      );
    } catch (e) {
      notification.setNotification(
        "error",
        "Change order confirmation failed!"
      );
    } finally {
      this._submitting = false;
    }
  };

  readonly handleChangeRequestApproveCompletion = async () => {
    this._submitting = true;
    const {
      budgetModel,
      projectDetailsFlow: { budget, selectedChangeRequest, selectedMilestone },
    } = this.props;

    try {
      await budgetModel.approveMilestoneCompletion(
        budget!.id,
        selectedMilestone?.id!,
        this.currentChangeRequest!.id
      );
      if (selectedMilestone?.type === "milestone") {
        (selectedMilestone as BudgetMilestone).completion = "completed";
        const currBudgetMilestone = budget?.milestones.find(
          (m) => m.id === selectedMilestone?.id
        );
        if (currBudgetMilestone) currBudgetMilestone.completion = "completed";
      }
      selectedChangeRequest!.currentStatus = "approved";
    } catch (error) {
      this.showApprovalErrorModal = true;
      console.error(error);
    }

    this._submitting = false;
  };

  readonly handleChangeRequestApproveProject = async () => {
    this._submitting = true;
    const {
      project,
      projectModel,
      projectDetailsFlow: { budget, selectedChangeRequest, selectedMilestone },
    } = this.props;

    try {
      await projectModel.archiveProject(project.id);
      if (selectedMilestone?.type === "milestone") {
        (selectedMilestone as BudgetMilestone).completion = "completed";
        const currBudgetMilestone = budget?.milestones.find(
          (m) => m.id === selectedMilestone?.id
        );
        if (currBudgetMilestone) currBudgetMilestone.completion = "completed";
      }
      selectedChangeRequest!.currentStatus = "approved";
    } catch (error) {
      this.showCompletionErrorModal = true;
      console.error(error);
    }

    this._submitting = false;
  };

  readonly handleChangeRequestCancel = async () => {
    this._submitting = true;
    const {
      projectDetailsFlow: { budget, selectedChangeRequest, selectedMilestone },
    } = this.props;
    try {
      await this.props.budgetModel.cancelChangeRequest(
        budget!.id,
        selectedMilestone?.id!,
        this.currentChangeRequest!.id,
        this.milestoneCompletion
      );
      selectedChangeRequest!.currentStatus = "cancelled";
      if (selectedMilestone?.type === "milestone") {
        (selectedMilestone as BudgetMilestone).completion =
          this.milestoneCompletion;
        const currBudgetMilestone = budget?.milestones.find(
          (m) => m.id === selectedMilestone?.id
        );
        if (currBudgetMilestone)
          currBudgetMilestone.completion = this.milestoneCompletion;
      }
    } catch (error) {
      console.error(error);
    }
    this._submitting = false;
  };

  readonly handleChangeRequestReject = async () => {
    this._submitting = true;
    const {
      projectDetailsFlow: { budget, selectedChangeRequest, selectedMilestone },
    } = this.props;
    try {
      await this.props.budgetModel.rejectChangeRequest(
        budget!.id,
        selectedMilestone?.id!,
        this.currentChangeRequest!.id,
        this.milestoneCompletion
      );
      selectedChangeRequest!.currentStatus = "cancelled";
      if (selectedMilestone?.type === "milestone") {
        (selectedMilestone as BudgetMilestone).completion =
          this.milestoneCompletion;
      }
    } catch (error) {
      console.error(error);
    }
    this._submitting = false;
  };

  renderBottomActionButtons = () => {
    if (this._submitting)
      return (
        <StyledButton
          disabled={true}
          loading={true}
          text={capitalizeFirstLetter(this.currentChangeRequest!.currentStatus)}
          textStyle={styles.bottomActionButtonsText}
          alignSelf
          style={styles.bottomActionButtons}
        />
      );

    // if approved
    // or if user is contractor waiting for owner to sign
    // or if user is owner waiting for contractor to sign
    if (
      this.currentChangeRequest?.currentStatus !== "submitted" ||
      (this.userType === "owner" &&
        this.currentChangeRequest?.currentStatus === "submitted" &&
        this.contractInfo?.ownerSigned &&
        !this.contractInfo?.contractorSigned)
    ) {
      return (
        <StyledButton
          disabled={true}
          text={capitalizeFirstLetter(this.currentChangeRequest!.currentStatus)}
          textStyle={styles.bottomActionButtonsText}
          alignSelf
          style={styles.bottomActionButtons}
        />
      );
    }

    if (
      this.userType === "contractor" &&
      this.contractInfo?.ownerSigned === true
    ) {
      return (
        <StyledButton
          disabled={
            !this.isConfirmationAddressChecked ||
            !this.isConfirmationTermsChecked
          }
          variant={"affirm"}
          onPress={this.handleChangeRequestConfirmationApproval}
          text={"Accept"}
          iconLeft={{
            size: 16,
            name: "check",
            type:
              !this.isConfirmationAddressChecked ||
              !this.isConfirmationTermsChecked
                ? "gray"
                : "affirm",
          }}
          textStyle={styles.bottomActionButtonsText}
          alignSelf
          style={styles.bottomActionButtons}
        />
      );
    }

    if (this.userType === "contractor") {
      return (
        <StyledButton
          variant={"warning"}
          onPress={this.handleChangeRequestCancel}
          text={"Cancel"}
          iconLeft={{ size: 16, name: "x", type: "warning" }}
          textStyle={styles.bottomActionButtonsText}
          alignSelf
          style={styles.bottomActionButtons}
        />
      );
    } else {
      return (
        <>
          <StyledButton
            disabled={
              !this.isConfirmationAddressChecked ||
              !this.isConfirmationTermsChecked
            }
            variant={"affirm"}
            onPress={this.handleChangeRequestApprove}
            text={"Accept"}
            iconLeft={{
              size: 16,
              name: "check",
              type:
                !this.isConfirmationAddressChecked ||
                !this.isConfirmationTermsChecked
                  ? "gray"
                  : "affirm",
            }}
            textStyle={styles.bottomActionButtonsText}
            alignSelf
            style={styles.bottomActionButtons}
          />
          <StyledButton
            variant={"warning"}
            onPress={this.handleChangeRequestReject}
            text={"Reject"}
            iconLeft={{ size: 16, name: "x", type: "warning" }}
            textStyle={styles.bottomActionButtonsText}
            alignSelf
            style={styles.bottomActionButtons}
          />
        </>
      );
    }
  };

  render() {
    const {
      project,
      projectDetailsFlow: { budget, selectedMilestone, selectedPhase },
      projectWidgetManager: { activeChangeRequestCommentIndex },
    } = this.props;
    if (!this._initialized) return <LoadingIndicator />;

    return (
      <>
        {/* Confirm Approval Modal */}
        <StyledModal
          onClose={() => (this.showConfirmApprovalModal = false)}
          onConfirm={() => {
            this.showConfirmApprovalModal = false;
            this.handleChangeRequestApproveCompletion();
          }}
          visible={this.showConfirmApprovalModal}
          title="Confirm Approval"
          text={
            "Are you sure you want to approve this change order? \n\nFunds will be released from escrow and transferred to the contractor."
          }
        />
        {/* Confirm Completion Approval Modal */}
        <StyledModal
          onClose={() => (this.showConfirmCompletionModal = false)}
          onConfirm={() => {
            this.showConfirmCompletionModal = false;
            this.handleChangeRequestApproveProject();
          }}
          visible={this.showConfirmCompletionModal}
          title="Confirm Approval"
          text={
            "Are you sure you want to approve this change order? \n\nProject will be archived and become immutable."
          }
        />
        {/* Approval Error Modal */}
        <StyledModal
          onClose={() => (this.showApprovalErrorModal = false)}
          visible={this.showApprovalErrorModal}
          title="Approval Error"
          text={
            "Completion of the milestone could not be approved. Please make sure the phase is fully funded under the Payments tabs and try again."
          }
        />
        {/* Completion Approval Error Modal */}
        <StyledModal
          onClose={() => (this.showCompletionErrorModal = false)}
          visible={this.showCompletionErrorModal}
          title="Approval Error"
          text={
            "Completion of the project could not be approved. Please try again."
          }
        />

        {/* Left Tab Section */}
        <View style={styles.leftTabSection}>{this.tabs}</View>

        {/* Body Section */}
        <View style={styles.changeRequestSection}>
          <View style={styles.changeRequestHeader}>
            <StyledText variant="body" isBold={true}>
              {formatChangeRequestTag(this.currentChangeRequest!)}
            </StyledText>
          </View>
          <View style={styles.title}>
            <StyledText
              variant="body2"
              colorMode="gray"
              style={{ marginTop: 4 }}
            >
              {project.name}
            </StyledText>
            <StyledText variant="body" isBold={true}>
              {this.newChangeRequestName || this.originalChangeRequestName}
            </StyledText>
          </View>
          {this._loadingDetail ? (
            <LoadingIndicator />
          ) : (
            <>
              {this.currentChangeRequest && (
                <InjectedChangeRequestView
                  changeRequest={this.currentChangeRequest}
                  commentsEnabled
                  selectedCommentIndex={activeChangeRequestCommentIndex}
                  style={{ marginHorizontal: -32 }}
                />
              )}
              <View style={styles.bodyClickWrap}>
                {this.showAcceptClickWrap && (
                  <ConfirmAgreementView
                    address={structureAddress(this.props.project.address)}
                    checkboxStateAddress={{
                      checked: this.isConfirmationAddressChecked,
                      onChange: () =>
                        (this.isConfirmationAddressChecked =
                          !this.isConfirmationAddressChecked),
                    }}
                    checkboxStateTerms={{
                      checked: this.isConfirmationTermsChecked,
                      onChange: () =>
                        (this.isConfirmationTermsChecked =
                          !this.isConfirmationTermsChecked),
                    }}
                  />
                )}
                <View style={styles.bodyButtons}>
                  {this.renderBottomActionButtons()}
                </View>
              </View>
            </>
          )}
        </View>

        {/* Sidebar Section */}
        <View style={styles.sidebarSection}>
          {((selectedMilestone && selectedPhase) ||
            this.currentChangeRequest?.changeRequestType === "final") && (
            <View style={styles.sidebarInner}>
              <InjectedManagementSidebarView
                budget={budget!}
                onEditPress={() => {}}
                project={project}
              />
            </View>
          )}
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  leftTabSection: {
    flex: 1,
    flexDirection: "column",
    zIndex: 2,
  },
  topTab: {
    flexDirection: "column",
    gap: 16,
  },
  tab: {
    flexDirection: "row",
    gap: 24,
    justifyContent: "center",
    alignItems: "center",
    height: 92,
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
    marginLeft: -84,
    paddingHorizontal: 24,
  },
  whiteRightBorder: {
    marginRight: -1,
    borderRightWidth: 1,
    borderRightColor: Palette.White,
  },
  circle: {
    width: 8,
    height: 8,
    backgroundColor: Palette.White,
    borderRadius: 4,
  },
  accentCircle: {
    backgroundColor: Palette.Accent,
  },
  lineThrough: {
    textDecorationLine: "line-through",
  },
  milestonesContainer: {
    flexDirection: "column",
    gap: 8,
    padding: 8,
    marginTop: 36,
    marginRight: 24,
    marginBottom: 98,
    backgroundColor: "#F4F6F7",
    border: "1px solid" + Palette.Primary10Pct,
    borderRadius: 8,
  },
  milestoneHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 16,
    marginVertical: 8,
  },
  changeRequestSection: {
    flex: 2,
    flexDirection: "column",
    borderLeftWidth: 1,
    borderLeftColor: Palette.Primary10Pct,
    zIndex: 1,
    paddingHorizontal: 32,
  },
  changeRequestHeader: {
    justifyContent: "center",
    height: 65,
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
  },
  title: {
    marginTop: 32,
    marginBottom: 16,
    gap: 4,
  },
  bodyClickWrap: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-end",
    gap: 32,
    marginBottom: 64,
  },
  bodyButtons: {
    flexDirection: "row",
    gap: 16,
  },
  sidebarSection: {
    flex: 1.5,
  },
  sidebarInner: {
    flex: 1,
    borderLeftWidth: 1,
    borderLeftColor: Palette.Primary10Pct,
  },
  row: {
    flexDirection: "row",
  },
  headerRight: {
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: "row",
    marginTop: 5,
  },
  bottomActionButtons: { width: 144, height: 48 },
  bottomActionButtonsText: { fontSize: 15, lineHeight: 24 },
});

export const InjectedProjectChangeRequestsView = withInjectedFactory(
  ProjectChangeRequestsViewFactory
);
